<template>
  <div class="main-layout-wrap">
    <multi-row-appbar-container
      :multi-row="splitter"
      :upper-bar-height="upperBarHeight"
      :lower-bar-height="lowerBarHeight"
    >
      <global-nav-header :height="upperBarHeight"></global-nav-header>
      <template #second>
        <div v-if="splitter" class="splitter"></div>
      </template>
    </multi-row-appbar-container>

    <v-main class="router-view-area">
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import GlobalNavHeader from "@/components/atomic/organisms/GlobalNavHeader.vue";
import MultiRowAppbarContainer from "@/components/atomic/organisms/MultiRowAppbarContainer";

export default {
  components: {
    GlobalNavHeader,
    MultiRowAppbarContainer,
  },
  props: {
    splitter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      upperBarHeight: 50,
      lowerBarHeight: 5,
    };
  },
  mounted() {
    if(!this.splitter) {
      this.lowerBarHeight = 0;
    }
  },

};
</script>

<style lang="scss" scoped>
.main-layout-wrap {
  display: flex;
  @include scrollAreaY();

  width: 100%;
  height: 100vh;

  .splitter {
    width: 100%;
    height: 5px;
    background-color: #0761B4;
  }

  .router-view-area {
    background-color: #F2F8FF;
  }
}
</style>
